import request from '@/utils/request'
//员工信息
export function GetEmployeeInfo() {
    return request.get("/api/vipmini/GetEmployeeInfo")
}

//邀请客户统计
export function GetListCountByUser() {
    return request.get("/api/vipmini/GetListCountByUser")
}
//邀请的客户
export const ListByUser = (obj) => {
    return request.post("/api/vipmini/ListByUser", obj);
};  