<template>
  <div class="container" ref="wrapper">
    <van-search v-model="page.filterData.moblie" @search="onSearch" placeholder="电话模糊匹配" />
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list v-model="upLoading" :finished="finished" @load="onLoad" finished-text="没有更多了">
        <van-cell-group style="background-color:#f2f2f2;">
          <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
          <van-cell v-for="(item, index) in articles" :key="item.id.toString()"
            style="margin:5px 0px;border-radius: 10px;">
            <div style="display: table-cell; vertical-align: middle;" class="zntitle van-multi-ellipsis--l3">
              <div style="
                    float: left;
                    margin-right: 10px;
                    text-align: center;
                    font-size: 15px;
                    color: #6c6c6c;
                  ">
                {{ index + 1 }}、
              </div>
              <span style="
                  color: #6c6c6c;
                    display: inline-block;
                    user-select: text;
                    -moz-user-select: text;
                    -webkit-user-select: text;
                    -ms-user-select: text;
                  ">
                {{ item.id }}
              </span>
            </div>
            <div class="iteminfo" v-if="item.moblie">
              <div class="left">
                <h3 style="
                    user-select: text;
                    -moz-user-select: text;
                    -webkit-user-select: text;
                    -ms-user-select: text;
                  ">
                  {{ item.moblie }}
                </h3>
              </div>
              <div class="right">
                <h3 style="
                    user-select: text;
                    -moz-user-select: text;
                    -webkit-user-select: text;
                    -ms-user-select: text;
                  ">
                  a3客户编号: {{ item.a3Code }}
                </h3>
              </div>

              <div class="right"  v-if="item.a3Name">
                <h3 style="
                    user-select: text;
                    -moz-user-select: text;
                    -webkit-user-select: text;
                    -ms-user-select: text;
                  ">
                  a3客户姓名: {{ item.a3Name }}
                </h3>
              </div>

            </div>
            <div class="iteminfo" v-else>
              <div class="left">
                <h3 style="
                    user-select: text;
                    -moz-user-select: text;
                    -webkit-user-select: text;
                    -ms-user-select: text;
                  ">
                  客户暂未绑定手机
                </h3>
              </div>
            </div>



            <div style="
                  user-select: text;
                  -moz-user-select: text;
                  -webkit-user-select: text;
                  -ms-user-select: text;
                "></div>


            <div v-if="item.parentName != undefined && item.parentName != ''">
              来自客户：
              <h3 style="
                    user-select: text;
                    -moz-user-select: text;
                    -webkit-user-select: text;
                    -ms-user-select: text;
                  ">
                {{ item.parentId }}
              </h3>
            </div>

            <div class="iteminfo">
              <div class="right">
                {{ item.creationTime }}
              </div>
            </div>
            
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
    <div v-if="A3Error" style="text-align: center">
      <van-button type="info" @click="toBindA3">{{ A3Message }}</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { ListByUser } from "@/api/vipmini";
export default {
  name: "answer",
  props: {
    questionnaireId: String,
  },
  data() {
    return {
      scroll: 0,
      refreshing: false,
      upLoading: false,
      finished: false,
      articles: [],
      A3Error: false,
      A3Message: "去绑定A3工号",
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          asyncA3: -2,
          title: "",
          phone: "",
          questionnaireId: "",
        },
      },
    };
  },
  watch: {
    questionnaireId(val, oldVal) {
      //普通的watch监听
      if (val != oldVal) {
        this.onSearch();
      }
    },
  },

  activated() {
    this.page.filterData.questionnaireId = this.questionnaireId;
    //this.onSearch();
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.page.filterData.questionnaireId = this.questionnaireId;
  },

  mounted() { },

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    async onLoad() {
      this.upLoading = true;
      let aresult = await ListByUser({ ...this.page }); //({ ...this.page })
      if (!aresult.data.success) {
        Toast(aresult.data.errorMessage);
        this.upLoading = false;
        this.finished = true;
        if (aresult.data.errorCode == "A3Error1") {
          this.A3Error = true;
          this.A3Message = "去绑定A3工号";
        }
        if (aresult.data.errorCode == "A3Error2") {
          this.A3Error = true;
          this.A3Message = "去设置默认A3工号";
        }

        return;
      }
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    toBindA3() {
      this.$router.push({
        path: "/user/binda3",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.iteminfo {

  margin: 2px 0px;
  min-height: 35px;
  background-color: #fff;
  display: flow-root;

  .left {
    float: left;
    margin: 3px;
    display: flex;
  }

  .right {
    float: right;
    margin: 3px;
    display: flex;
  }
}
</style>