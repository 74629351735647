<template>
  <div class="container" ref="wrapper">
    <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
      长按图片可下载或分享
    </van-notice-bar>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-cell-group>
          <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
          <van-cell v-for="item in articles" :key="item.id.toString()">
            <div
              style="display: table-cell; vertical-align: middle"
              class="zntitle van-multi-ellipsis--l3"
            >
              <h3 style="display: inline-block">{{ item.title }}</h3>
            </div>

            <div class="acontent">
              <van-image
                lazy-load
                class="w100"
                fit="contain"
                width="50%"
                :src="item.qrPrviewImage"
              />
            </div>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
    <div v-if="A3Error" style="text-align: center">
      
      <van-button type="info" @click="toBindA3">{{ A3Message }}</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import { getPosterList } from "@/api/questionnaire";
export default {
  name: "poster",
  props: {
    questionnaireId: String,
  },

  data() {
    return {
      scroll: 0,
      refreshing: false,
      upLoading: false,
      finished: false,
      articles: [],
      A3Error: false,
      A3Message: "去绑定A3工号",
      A3Message2: "您还没有A3编号标识，请点击按钮先去绑定A3工号",
      page: {
        current: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          title: "",
          questionnaireId: "",
        },
      },
    };
  },
  watch: {
    questionnaireId(val, oldVal) {
      //普通的watch监听
      if (val != oldVal) {
        this.onSearch();
      }
    },
  },

  activated() {
    this.page.filterData.questionnaireId = this.questionnaireId;
    //this.onSearch();
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.page.filterData.questionnaireId = this.questionnaireId;
  },
  mounted() {},
  updated() {
    this.addpreview();
  },

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },
    async onLoad() {
      this.upLoading = true;
      let aresult = await getPosterList({ ...this.page }); //({ ...this.page })

      if (!aresult.data.success) {
        Toast(aresult.data.errorMessage);
        this.upLoading = false;
        this.finished = true;
        if (aresult.data.errorCode == "A3Error1") {
          this.A3Error = true;
          this.A3Message = "去绑定A3工号";
          this.A3Message2="您还没有A3编号标识，请点击按钮先去绑定A3工号"
        }
        if (aresult.data.errorCode == "A3Error2") {
          this.A3Error = true;
          this.A3Message = "去设置默认A3工号";
          this.A3Message2="您有多个A3工号， 点击按钮设置您的默认工号"
        }
        return;
      }

      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },

    toBindA3() {
      this.$router.push({
        path: "/user/binda3",
      });
    },

    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");
      listdiv.forEach((acontentdiv) => {
        var imgs = acontentdiv.getElementsByTagName("img");

        imgs.forEach((element) => {
          element.setAttribute("preview", this.questionnaireId);
        });
      });
      this.$previewRefresh();
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
</style>
