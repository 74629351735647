<template>
  <div class="container">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">

    </van-nav-bar>
    <div class="NavTitle" v-if="!HasBindA3 && this.typeId == 1">
      <h3 style="text-align: center; margin: 50px 10px;">
        请去绑定A3工号，再来查看海报。
      </h3>
      <van-button type="info" size="large" block @click="tobinda3">去绑定</van-button>
    </div>


    <van-tabs v-model="activeName" sticky v-else>
      <van-tab title="小程序海报" name="a">
        <div class="tabcontent">
          <div  style="display: flex; justify-content: space-between;" @click="showMiniImg()">
            <div style="width: 70%;">
              <div v-if="employeeError != ''" class="employError"> {{ employeeError }}</div>
              <div v-else class="employInfo">
                <p class="info">请核对您的信息</p>
                <div>A3工号：<span class="big">{{ employeeInfo.employeeNumber }}</span></div>
                <div>个人手机号： <span class="big">{{ employeeInfo.phone }}</span></div>
              </div>
            </div>
            <div style="width: 70%;">
                设置个人手机号
            </div>
          </div>
          <Poster :questionnaireId="questionnaireId" :key="questionnaireId"></Poster>
        </div>
      </van-tab>

      <van-tab title="小程序用户" name="b">
        <div class="tabcontent">
          <MiniUser :questionnaireId="questionnaireId" :key="questionnaireId"></MiniUser>
        </div>
      </van-tab>


      <van-tab title="用户统计" name="c">
        <div class="tabcontent">
          <MiniStat :questionnaireId="questionnaireId" :key="questionnaireId"></MiniStat>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>

import {
  GetA3UserInfo,
} from "@/api/UserToA3";
import { Toast } from "vant";

import { getQuestionnaire } from "@/api/questionnaire";
import { GetEmployeeInfo } from "@/api/vipmini";

import Poster from "./components/poster";
import MiniUser from "./components/miniUser";
import MiniStat from "./components/miniStat";
//const key = "zn-history";
export default {
  name: "question-minidetail",
  data() {
    return {
      HasBindA3: true,
      typeId: 0,
      HasBindList: [],
      questionnaireId: "",
      scroll: 0,
      activeName: "a",
      title: "小程序详情",
      articles: {},
      employeeError: "",
      employeeInfo: {},
    };
  },
  components: {
    Poster,
    MiniUser,
    MiniStat,
  },
  activated() { },
  created() {
    this.initData();
  },
  updated() {
    //this.showQW();
  },
  watch: {
    $route(to, from) {
      //监听路由是否变化
      console.log("to", to)
      console.log("from", to)
      if (to.path == "/question/minidetail") {
        if (to.query != from.query) {
          this.initData();
          console.log("init")
        }
      }
    },
  },

  methods: {
    async initData() {
      if (this.$route.path == "/question/minidetail") {
        this.questionnaireId = this.$route.query.id;

        const data = await GetA3UserInfo();
        if (data.data.success) {
          this.HasBindList = data.data.data;
          if (this.HasBindList.length > 0) {
            this.HasBindA3 = true;

          }
          else {
            this.HasBindA3 = false;
            this.title = "缺少A3工号"
          }
        } else {
          Toast(data.data.errorMessage);
        }

        //必须有A3工号

        let aresult = await getQuestionnaire(this.questionnaireId); //({ ...this.page })

        this.articles = aresult.data.data;
        this.typeId = aresult.data.data.typeId;
        this.title = this.articles.title;

        let eresult = await GetEmployeeInfo();
        if (eresult.data.success == false) {
          this.employeeError = eresult.data.errorMessage
        }
        else {
          this.employeeInfo = eresult.data.data;
        }



      }
    },
    tobinda3() {
      this.$router.push({
        path: "/user/binda3",
        query: {
          back: true
        },
      });
    },
    showMiniImg(){

    }

  },
};
</script>

<style lang="less" scoped>
.w100 {
  /deep/ img {
    width: 100%;
    max-height: 200px;
  }
}

.zntitle {
  height: 30px;
}

.employError {
  display: flex;
  justify-content: center;
  /* 水平居中 */
  align-items: center;
  /* 垂直居中 */
  height: 100px;
  /* 容器高度 */
  color: red;
  background-color: #000;
  font-size: 24px;

}


.employInfo {

  margin: 2px 0px;
  //height: 100px;
  background-color: #fff;

  .left {
    float: left;
    margin: 10px;
    display: flex;
  }

  .right {
    float: right;
    margin: 10px;
    display: flex;
  }
}

.big {
  font-size: 18px;
  font-weight: 700;
}

.container {
  height: 100%;
  //overflow-y: auto;
  //box-sizing: border-box;

  /deep/.van-tabs {
    height: 100%;
  }

  /deep/.van-tabs__content {
    height: 100%;
  }
}

.van-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;

  /deep/ .van-tabs__wrap {
    height: 36px;
    padding-right: 36px;

    .van-tab {
      line-height: 36px;
    }

    .van-tabs__line {
      background-color: #3296fa;
      height: 2px;
    }
  }

  /deep/ .van-tabs__content {
    flex: 1;
    overflow: hidden;
  }

  /deep/ .van-tab__pane {
    height: 100%;

    .scroll-wrapper {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.tabcontent {
  height: 100%;
  margin-bottom: 138px;
}
</style>